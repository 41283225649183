<template>
    <div style="width: 100%; overflow: hidden; position: relative" id="parent">
        <div class="left_tab_parent">
            <div class="left_tab">
                <div
                        v-for="(item, index) in tab_list"
                        :key="index"
                        :class="{ isChoose: isChoose == index }"
                        @click="showTab(index)"
                >
                    {{ item }}
                </div>
            </div>
        </div>
        <div class="right_content_parent">
            <div class="right_content">
                <component
                        :is="componentsList[isChoose]"
                        v-if="forceUpdate"
                ></component>
            </div>
        </div>
        <div class="enterprise-footer" style="text-align: center">
            <a style="color: #515a6e" href="https://beian.miit.gov.cn/" target="_blank">连云港科技大市场版权所有2013-2024
                苏ICP备18024573号-2. All Rights Reserved.</a>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import weipay from "@/assets/image/science/wepay.png";
    import alipay from "@/assets/image/science/alipay.png";
    import money from "@/assets/image/science/money.png";

    export default {
        name: "PatentTrusteeship",
        mounted() {
            this.isChoose = this.$route.query.id;
            this.changeHeight();
        },
        computed: {},
        components: {
            AnnuaFreeCollection: () =>
                import("@/components/science/PatentTrusteeship/AnnuaFreeCollection.vue"),
            FindPatent: () =>
                import("@/components/science/PatentTrusteeship/FindPatent.vue"),
            PatentTrusteeshipCom: () =>
                import("@/components/science/PatentTrusteeship/PatentTrusteeshipCom.vue"),
            SalePatent: () =>
                import("@/components/science/PatentTrusteeship/SalePatent.vue"),
        },
        data() {
            return {
                forceUpdate: true,
                componentsList: [
                    "PatentTrusteeshipCom",
                    "AnnuaFreeCollection",
                    "SalePatent",
                    "FindPatent",
                ],

                tab_height: 0,
                tab_list: ["专利托管", "年费代缴", "出售专利", "求购专利"],
                isChoose: 0,
            };
        },
        methods: {
            showTab(index) {
                this.forceUpdate = false;
                this.isChoose = index;
                this.$nextTick(() => {
                    this.$router.push({
                        path: "/enterprise/patent-trusteeship",
                        query: {
                            id: this.isChoose,
                        },
                    });
                    this.forceUpdate = true;
                    this.changeHeight();
                });
            },

            changeHeight() {
                let that = this;
                this.$nextTick(() => {
                    that.tab_height =
                        Number($(".right_content_parent").height()) >
                        Number(document.body.clientHeight) - 101
                            ? $(".right_content_parent").height() + "px"
                            : Number(document.body.clientHeight) - 101 + "px";
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    #button_style div:nth-child(1) {
        margin-left: 50px;
    }

    #button_style div:nth-child(2) {
        margin-left: 25px;
    }

    .div_button {
        cursor: pointer;
        border-width: 1px;
        border-color: rgb(220, 220, 220);
        border-style: solid;
        border-radius: 4px;
        display: inline-block;
        width: 114px;
        height: 40px;
        line-height: 40px;
        text-align: center;
    }

    .div_button_select {
        cursor: pointer;
        border-width: 1px;
        border-color: #1767E0;
        border-style: solid;
        border-radius: 4px;
        display: inline-block;
        width: 114px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #1767E0;
    }

    .pay_div {
        display: flex;
        height: 60px;
        width: 180px;
        border: 1px solid rgb(205, 240, 243);
        margin-left: 20px;
        font-size: 20px;
        align-items: center;
    }

    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted rgb(218, 226, 229);
    }

    .div_center_two {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        /*border-bottom: 2px dotted rgb(218, 226, 229)*/
    }

    /deep/ #djnf .ivu-col {
        border-right: 1px solid #DFE6EC;
        border-top: 1px solid #DFE6EC;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
    }

    /deep/ #djnf .ivu-row .ivu-col:last-child {
        border-right: none;
        // border-top: 1px solid rgba(205, 240, 243, 1);
    }

    /deep/ .ivu-modal-footer {
        border-top: none;
    }

    /deep/ #textarea .ivu-input-wrapper {
        width: 80%;
    }

    /deep/ .ivu-modal-footer button:nth-child(1) {
        background-color: rgb(253, 167, 54);
        border: none !important;
        color: white;
    }

    /deep/ .ivu-modal-footer button:nth-child(2) {
        background-color: #1767E0;
        border: none !important;
        color: white;
    }

    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;

        .ivu-modal {
            top: 0;
        }
    }

    /deep/ .ivu-modal-content {
        border-radius: 0 !important;
    }

    /deep/ .ivu-modal-header-inner {
        text-align: center;
        background-color: rgb(245, 246, 250);
    }

    /deep/ .ivu-modal-header {
        background-color: rgb(245, 246, 250);
    }

    .neirong {
        margin-left: 130px;

        .download_span {
            color: blue;
            display: inline-block;
            margin-right: 30px;
        }

        p {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
        }
    }

    .bottom_style {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: -110px;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        overflow: hidden;
    }

    .right_header {
        width: 95%;
        padding-top: 20px;
        height: 140px;
        position: relative;
        margin-bottom: 40px !important;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #f5f5f5;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        font-size: 14px;
        margin-left: 10px;
        display: inline-block;
        background-color: #1767E0;
        z-index: 51;
        text-align: center;
        color: white;
        cursor: pointer;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    .isChoose {
        color: #37c5cf;
    }

    .right_content {
        background-color: rgb(255, 255, 255);
        min-width: 1210px;
        height: auto;
    }

    .right_content_parent {
        // width: 90%;
        min-width: 1210px;
        // float: left;
        margin-left: 160px;
        // padding-top: 10px;
        position: relative;
        min-height: calc(100% - 46px);
    }

    .left_tab_parent {
        height: 100%;
        min-width: 160px;
        width: 160px;
        background-color: #D5E7FA;
        position: fixed;
        top: 101px;

        .left_tab {
            div {
                height: 61px;
                line-height: 61px;
                font-size: 14px;
                color: #597697;
                padding-left: 20px;
                cursor: pointer;
                border-bottom: 1px solid #BDDBFA;

                &.isChoose {
                    color: #fff;
                    background-color: #1767E0;
                }
            }
        }
    }

    /deep/ .ivu-input {
        height: 32px !important;
        border-radius: 4px !important;
    }

    /deep/ .ivu-select-selection {
        height: 32px !important;
        border-radius: 4px !important;
    }

    /deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder {
        line-height: 32px !important;
    }

    /deep/ .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
        line-height: 32px !important;
    }
</style>
